.container{
    display: flex;
}


.link{
    text-decoration: none;
    color: inherit;
}

.MuiDataGrid-cell{
    padding: 0 15px!important;
}

.MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 10px!important;
    justify-content: center!important;
}

.MuiDataGrid-cellWithRenderer {
    justify-content: center!important;
}

.MuiDataGrid-cell   {
    text-align: center!important;
}

.css-13cymwt-control { 
    border-color: black!important;
}

.globalContainer {
}

.loginBackground {
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      ),
      url("https://ik.imagekit.io/dnddecpho/SSR/jdm_f_MEhDsab.jpg?updatedAt=1717319347589")
        center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.productListButton {
    border: none;
    border-radius: 10px;
    padding: 15px 0px;
    background-color: #014451;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.h-30vh {
    height: 30vh;
}

.w-70vw {
    width: 70vw;
}

.productDetails {
    margin: 5px 0px;
}

.updateProductTitle {
    font-size: 1.4rem;
    font-weight: 600;
}